.title-product{
    font-family: sans-serif;
    font-size: 24px;
    color: #222222;
    line-height: 1;
    margin-top: 0%;
    margin-bottom: 5%;
    text-transform: capitalize;

}

.price-product{
    font-family: sans-serif;
    font-size: 25px;
    color:#555555;
    line-height: 1.2;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
}

.title-stock-product{
    font-family: sans-serif;
    color:#555555;
    font-size: 15px;
    padding-top: 6px;
    width: 45px;
    margin-right: 5px;
    display: inline-block;
    font-weight: bold;


}

.stock-quantity-product{
  color: green;
  margin-top: 0%;
  display: inline-block;
}
.title-quantity-product{
    font-family: sans-serif;
    color:#555555;
    font-size: 15px;
    margin-top: 0%;
    margin-bottom: 2%;
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
    
}


.agregar-input{
	padding:15px 10px;
	width:50%;
	outline:none;
	border:1px solid #bbb;
    border-radius:15px;
    height: 15%;
	display:inline-block;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
    -webkit-transition:0.2s ease all;
	   -moz-transition:0.2s ease all;
	    -ms-transition:0.2s ease all;
	     -o-transition:0.2s ease all;
	        transition:0.2s ease all;
}


.boton-agregar{
    margin-top: 20px;
    font-weight: bold;
    font-size: 15px;
    padding: 10px;
    background-color: indigo;
    width: 100%;
    outline:none;
    border-radius: 15px;
    color: #FFF;
    cursor: pointer;
    transition: background-color .3s ease;
}

