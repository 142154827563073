.preloader {
	width: 70px;
	height: 70px;
	border: 10px solid #eee;
	border-top: 10px solid #4C0F99;
	border-radius: 50%;
	animation-name: girar;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@keyframes girar {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

html,
body {
	margin: 0;
}
.div_contenedor {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.div_centrado {
	width: 200px;
	height: 200px;
}
