
.page_404{ padding:80px 0; 
    background:#fff; 
    font-family: 'Arvo', sans-serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{

background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
height: 600px;
background-position: center;
}


.four_zero_four_bg h1{
font-size:80px;
}

.four_zero_four_bg h3{
      font-size:80px;
      }
      
      .link_404{			 
color: #fff!important;
padding: 10px 20px;
background: #39ac31;
margin: 20px 0;
display: inline-block;}

.contant_box_404{ margin-top:-50px;}

.boton{
display: flex;
justify-content: center;
}

.button{
text-decoration:none;
font-weight: 600;
font-size: 20px;
color:#ffffff;
padding-top:15px;
padding-bottom:15px;
padding-left:40px;
padding-right:40px;
background-color:#f2595e;
border-color: #F69DA1;
border-width: 3px;
border-style: solid;
}
